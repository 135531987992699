.navbar {
  height: 64px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  //box-shadow:  0 0 8px 8px rgba(0,0,0,7%);
  //box-shadow:  0 0 6px 6px #FFAA3344;
  //box-shadow:  0 0 12px 6px #FFAA3388;
  box-shadow:  0 0 12px 6px #A91F3288;
  margin-bottom: 14px;
  z-index: 99999;
  color: #666;
  font-weight: bold;
  font-size: 28px;

}

a {
  text-decoration: none;
  color: inherit;
  padding: 12px;
  transition: ease-out 0.4s;
  border: 8px solid transparent;

  &:hover {
    border: 8px solid;
    border-radius: 40px;
  }

}

.title {
  display: flex;
  align-items: center;

  div:first-child {
    margin-right: 12px;
    font-size: 48px;
    margin-bottom: 8px;
  }
}
