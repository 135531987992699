.footer {
  height: 500px;
  color: #A91F32;
  text-shadow: 2px 1px 2px #ffffff88;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  > div:first-child {
    font-size: 32px;
    margin-bottom: 32px;
  }

  > div:nth-child(2) {
    padding-bottom: 12px;
  }

  > div:nth-child(3) {
    padding-bottom: 32px;
  }

  > div:nth-child(4) {
    > *:first-child {
      margin-right: 16px;
    }
  }
}

@media (max-width: 450px) {
  .footer {
    > div:first-child {
      font-size: 28px;
    }
  }
}
