.home-container {
}

.teafield {
  padding: 48px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .row {
    display: flex;
  }

  .cell {
    height: 64px;
    width: 64px;
    margin: 8px;
    background: #1E4785;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    transition: 2s;
    border-radius: 4px;
  }

  .selected {
    background: #A91F32;
  }
}

.go-button-container {
  display: flex;
  justify-content: center;
  padding: 24px 0 48px 0;
}

.go-button {
  background: #A91F32;
  border: 0;
  border-radius: 16px;
  padding: 16px;
  color: white;
  font-size: 24px;
  font-weight: bold;
  box-shadow: 2px 4px 4px #1E478588;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.result {
  font-weight: bold;
  font-size: 24px;
  color: #A91F32;
  text-shadow: 1px 1px 1px #1E478544;
  margin-bottom: 24px;
  background: #fffa;
  padding: 24px;
  border-radius: 24px;
  animation: fadeIn 1.5s ease-out;
}

.result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeIn 1.5s ease-out;
}

.meer-informatie {
  text-align: center;
  color: #A91F32;
  font-weight: bold;
  font-size: 18px;
  padding-top: 64px;
  text-shadow: 1px 1px 1px #1E478544;
}

.its-a-teapot {
  text-align: center;
  padding: 64px 0;
  font-size: 200px;
}
